import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hide: 1,
    unify_code: "", //调查报告 公司社会统一信用代码
    number:'',//  待办编号
    apply_number:'',//  待办 保函编号
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        console.log('1111111111111111111111111111',val);
        return {
          // 只储存state中的unify_code
          unify_code: val.unify_code,
          // 
          apply_number: val.apply_number,
          number: val.number,

        };
      },
    }),
  ],
  getters: {},
  mutations: {
    OPENSHOW(state, step) {
      state.hide = step;
    },
    OPENCODE(state, step) {
      state.unify_code = step;
    },
    OPENNUMBER(state, step) {
      state.number = step;
    },
    OPENAPPLY_NUMBER(state, step) {
      state.apply_number = step;
    },
  },
  actions: {
    AsyicOPENSHOW(context, step) {
      context.commit("OPENSHOW", step);
    },
    AsyicOPENCODE(context, step) {
      context.commit("OPENCODE", step);
    },
    AsyicOPENNUMBER(context, step) {
      context.commit("OPENNUMBER", step);
    },
    AsyicOPENAPPLY_NUMBER(context, step) {
      context.commit("OPENAPPLY_NUMBER", step);
    },
  },
  modules: {},
});
