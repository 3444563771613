import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/homepage/homepage",
    children: [
      //首页
      {
        path: "homepage/homepage",
        name: "homepage",
        component: () => import("../views/homepage/HomepageView.vue"),
      },
      //  工程信息 //采购公告
      {
        path: "information/Purchase",
        name: "Purchase",
      },
      //公开招标
      {
        path: "information/Purchase/gkzb",
        name: "gkzb",
        component: () => import("../views/information/Purchase/GkzbView.vue"),
        meta: { title: "投标保函", keepAlive: true },
      },
      //竞争性谈判公告
      {
        path: "information/Purchase/negotiation",
        name: "negotiation",
        component: () =>
          import("../views/information/Purchase/NegotiationView.vue"),
        meta: { title: "履约保函", keepAlive: true },
      },
      //竞争性磋商公告
      {
        path: "information/Purchase/consultations",
        name: "consultations",
        component: () =>
          import("../views/information/Purchase/ConsultationsView.vue"),
        meta: { title: "业主支付保函", keepAlive: true },
      },
      //邀请招标公告
      {
        path: "information/Purchase/invitation",
        name: "invitation",
        component: () =>
          import("../views/information/Purchase/InvitationView.vue"),
        meta: { title: "农民工工资保函", keepAlive: true },
      },
      //询价公告
      {
        path: "information/Purchase/inquiry",
        name: "inquiry",
        component: () =>
          import("../views/information/Purchase/InquiryView.vue"),
        meta: { title: "质量保函", keepAlive: true },
      },
      //单一来源公告
      {
        path: "information/Purchase/source",
        name: "source",
        component: () => import("../views/information/Purchase/SourceView.vue"),
        meta: { title: "预付款保函", keepAlive: true },
      },
      //资格预审
      {
        path: "information/Purchase/Prequalification",
        name: "Prequalification",
        component: () =>
          import("../views/information/Purchase/PrequalificationView.vue"),
      },
      //  工程信息 //结果公告
      {
        path: "information/result",
        name: "result",
      },
      {
        path: "information/result/bid",
        name: "bid",
        component: () => import("../views/information/result/BidView.vue"),
      },
      {
        path: "information/result/deal",
        name: "deal",
        component: () => import("../views/information/result/DealView.vue"),
      },

      //员工管理
      {
        path: "staff/account",
        name: "account",
        component: () => import("../views/staff/AccountView.vue"),
      },
      {
        path: "staff/accounts",
        name: "account",
        component: () => import("../views/staff/Accounts.vue"),
      },

      {
        path: "staff/jurisdiction",
        name: "jurisdiction",
        component: () => import("../views/staff/JurisdictionfView.vue"),
      },

      //保函管理

      {
        path: "Guarantee/apply",
        name: "apply",
        component: () => import("../views/Guarantee/ApplyView.vue"),
      },
      {
        path: "Guarantee/list",
        name: "list",
        component: () => import("../views/Guarantee/ListView.vue"),
      },
      {
        path: "Guarantee/guarantee",
        name: "guarantee",
        component: () => import("../views/Guarantee/GuaranteeView.vue"),
      },
      // 财务管理
      {
        path: "finance/payment",
        name: "payment",
        component: () => import("../views/finance/PaymentView.vue"),
      },
      {
        path: "finance/refund",
        name: "refund",
        component: () => import("../views/finance/RefundView.vue"),
      },
      {
        path: "finance/invoice",
        name: "invoice",
        component: () => import("../views/finance/InvoiceView.vue"),
      },
      // 经办人
      {
        path: "qualifications/qlist",
        name: "qlist",
        component: () => import("../views/qualifications/QlistView.vue"),
      },
      {
        path: "qualifications/examine",
        name: "examine",
        component: () => import("../views/qualifications/ExamineView.vue"),
      },
      // 开票管理
      {
        path: "kaipiao/dzzp",
        name: "kaipiao/dzzp",
        component: () => import("../views/kaipiao/dzzp.vue"),
        meta: { title: "开票管理" },
      },
      // 组织架构
      {
        path: "xitong/zuzhi",
        name: "xitong/zuzhi",
        component: () => import("../views/xitong/zuzhi.vue"),
        meta: { title: "组织架构" },
      },
      // 部门列表
      {
        path: "xitong/bumenList",
        name: "xitong/bumenList",
        component: () => import("../views/xitong/bumenList.vue"),
        meta: { title: "部门列表" },
      },
      // 代理公司列表
      {
        path: "Guarantee/dailiList",
        name: "Guarantee/dailiList",
        component: () => import("../views/Guarantee/dailiList.vue"),
        meta: { title: "代理公司列表" },
      },
      // 项目库
      {
        path: "xiangmu/xmku",
        name: "xiangmu/xmku",
        component: () => import("../views/xiangmu/xmku.vue"),
        meta: { title: "项目库" },
      },
      // 项目工程列表
      {
        path: "xiangmu/gcList",
        name: "xiangmu/gcList",
        component: () => import("../views/xiangmu/gcList.vue"),
        meta: { title: "项目工程列表" },
      },
      // 项目工程列表  详情
      {
        path: "xiangmu/detail",
        name: "xiangmu/detail",
        component: () => import("../views/xiangmu/detail.vue"),
        meta: { title: "详情" },
      },
      // 工程列表
      {
        path: "daihouguanli/list",
        name: "daihouguanli/list",
        component: () => import("../views/daihouguanli/list.vue"),
        meta: { title: "工程列表" },
      },
      // 专家列表
      {
        path: "zjps/zjList",
        name: "zjps/zjList",
        component: () => import("../views/zjps/zjList.vue"),
        meta: { title: "专家列表" },
      },
      // 会议资料
      {
        path: "zjps/zlList",
        name: "zjps/zlList",
        component: () => import("../views/zjps/zlList.vue"),
        meta: { title: "会议资料" },
      },
      // 评审会议
      {
        path: "zjps/hyList",
        name: "zjps/hyList",
        component: () => import("../views/zjps/hyList.vue"),
        meta: { title: "评审会议" },
      },
      // 评审决议
      {
        path: "zjps/jieguo",
        name: "zjps/jieguo",
        component: () => import("../views/zjps/jieguo.vue"),
        meta: { title: "评审决议" },
      },
      // 企业资料
      {
        path: "/personal",
        name: "PersonalView",
        component: () => import("../views/PersonalView.vue"),
      },
      // 个人资料
      {
        path: "/personalUser",
        name: "PersonalUserView",
        component: () => import("../views/PersonalUserView.vue"),
        meta: { title: "个人资料" },
      },
      //实名认证
      {
        path: "/RealName/PersonalRealname",
        name: "PersonalRealname",
        component: () => import("../views/RealName/PersonalRealname.vue"),
        meta: { title: "实名认证" },
      },
      //审计报告
      {
        path: "/finance/shenji",
        name: "finance/shenji",
        // redirect:"/finance/shenji/cash",
        component: () => import("../views/finance/shenji.vue"),
        // children:[{
        //   path:'/finance/shenji/cash',
        //   name:"cash",
        //   component: () => import("../views/finance/cash.vue"),
        // },
        // {
        //   path:'/finance/shenji/profits',
        //   name:"profits",
        //   component: () => import("../views/finance/profits.vue"),
        // },
        // {
        //   path:'/finance/shenji/Liability',
        //   name:"Liability",
        //   component: () => import("../views/finance/Liability.vue"),
        // }],
        meta: { title: "审计报告" },
      },
      //我的保函
      {
        path: "/wdbh/baohanList",
        name: "wdbh/baohanList",
        component: () => import("../views/wdbh/baohanList.vue"),
        meta: { title: "我的保函" },
      },

      //工程进度
      {
        path: "/daihouguanli/jindu",
        name: "daihouguanli/jindu",
        component: () => import("../views/daihouguanli/jindu.vue"),
        meta: { title: "工程进度" },
      },
      //缴费记录
      {
        path: "/daihouguanli/jiaofei",
        name: "daihouguanli/jiaofei",
        component: () => import("../views/daihouguanli/jiaofei.vue"),
        meta: { title: "缴费记录" },
      },
      //合同模板
      {
        path: "/xitong/muban",
        name: "xitong/muban",
        component: () => import("../views/xitong/muban.vue"),
        meta: { title: "合同模板" },
      },
      //个人反担保记录
      {
        path: "/wdbh/fandianbao",
        name: "wdbh/fandianbao",
        component: () => import("../views/wdbh/fandianbao.vue"),
        meta: { title: "个人反担保记录" },
      },
      //反担保用户列表
      {
        path: "/wdbh/userList",
        name: "wdbh/userList",
        component: () => import("../views/wdbh/userList.vue"),
        meta: { title: "反担保用户列表" },
      },
      //系统通知
      {
        path: "/msg/index",
        name: "msg/index",
        component: () => import("../views/msg/index.vue"),
        meta: { title: "系统通知" },
      },
      //待办事项
      {
        path: "/information/Purchase/All",
        name: "information/Purchase/All",
        component: () => import("../views/information/Purchase/All.vue"),
        meta: { title: "待办事项" },
      },
      //保函详情
      {
        path: "/wdbh/bhxq",
        name: "wdbh/bhxq",
        component: () => import("../views/wdbh/bhxq.vue"),
        meta: { title: "保函详情" },
      },
      //保函审批
      {
        path: "/wdbh/shenhe",
        name: "/wdbh/shenhe",
        component: () => import("../views/wdbh/shenhe.vue"),
        meta: { title: "保函审批" },
      },
      //解保列表
      {
        path: "/wdbh/jiebaoList",
        name: "/wdbh/jiebaoList",
        component: () => import("../views/wdbh/jiebaoList.vue"),
        meta: { title: "解保列表" },
      },
      //续保列表
      {
        path: "/wdbh/xubaoList",
        name: "/wdbh/xubaoList",
        component: () => import("../views/wdbh/xubaoList.vue"),
        meta: { title: "续保列表" },
      },
      //审计报告详情
      {
        path: "/finance/detail",
        name: "finance/detail",
        component: () => import("../views/finance/detail.vue"),
        meta: { title: "审计报告详情" },
      },
      //代理公司详情详情
      {
        path: "/Guarantee/detail",
        name: "Guarantee/detail",
        component: () => import("../views/Guarantee/detail.vue"),
        meta: { title: "代理公司详情" },
      },
      {
        path: "/Guarantee/companyDetail",
        name: "Guarantee/companyDetail",
        component: () => import("../views/Guarantee/companyDetail.vue"),
        meta: { title: "施工单位详情" },
      },
      {
        path: "/kuaidi",
        name: "kuaidi",
        component: () => import("../views/kuaidi.vue"),
        meta: { title: "快递" },
      },
      {
        path: "/exxpress",
        name: "exxpress",
        component: () => import("../views/exxpress.vue"),
      },
      {
        path: "/daihouguanli/detail",
        name: "daihouguanli/detail",
        component: () => import("../views/daihouguanli/detail.vue"),
      },
      {
        path: "/information/Purchase/dztb",
        name: "information/Purchase/dztb",
        component: () => import("../views/information/Purchase/dztb.vue"),
        meta: { title: "在线保函", keepAlive: true },
      },
      {
        path: "/wdbh/fangqi",
        name: "/wdbh/fangqi",
        component: () => import("../views/wdbh/fangqi.vue"),
      },
      {
        path: "/diaocha/baogaoList",
        name: "diaocha/baogaoList",
        component: () => import("../views/diaocha/baogaoList.vue"),
        meta: { title: "调查报告" },
      },
      {
        path: "/diaocha/detail",
        name: "diaocha/detail",
        component: () => import("../views/diaocha/detail.vue"),
        meta: { title: "调查报告详情" },
      },
      {
        path: "/information/Purchase/zxbhxq",
        name: "information/Purchase/zxbhxq",
        component: () => import("../views/information/Purchase/zxbhxq.vue"),
        meta: { title: "在线保函详情" },
      },
    ],
  },
  {
    path: "/login",
    name: "loginView",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/registers",
    name: "registers",
    component: () => import("../views/register.vue"),
  },
  {
    path: "/register",
    name: "registerView",
    component: () => import("../views/RegisterView.vue"),
  },

  //详情页
  {
    path: "/details",
    name: "DetailsView",
    component: () => import("../views/DetailsView.vue"),
  },
  {
    path: "/BidSecurity/SelectALot",
    name: "SelectALot",
    component: () => import("../views/BidSecurity/SelectALot.vue"),
  },
  //2
  {
    path: "/BidSecurity/financialInstitution",
    name: "financialInstitution",
    component: () => import("../views/BidSecurity/financialInstitution.vue"),
  },
  {
    path: "/BidSecurity/Submission",
    name: "SubmiSsion",
    component: () => import("../views/BidSecurity/Submission.vue"),
  },
  //4
  {
    path: "/BidSecurity/bidFrom",
    name: "bidFrom",
    component: () => import("../views/BidSecurity/bidFrom.vue"),
  },
  //5
  {
    path: "/BidSecurity/ElectronicProtocol",
    name: "ElectronicProtocol",
    component: () => import("../views/BidSecurity/ElectronicProtocol.vue"),
  },
  //6
  {
    path: "/BidSecurity/BidPayment",
    name: "BidPayment",
    component: () => import("../views/BidSecurity/BidPayment"),
  },
  //7
  {
    path: "/BidSecurity/puBlic",
    name: "puBlic",
    component: () => import("../views/BidSecurity/puBlic.vue"),
  },

  {
    path: "/RealName/RealnameInformation",
    name: "RealnameInformation",
    component: () => import("../views/RealName/RealnameInformation.vue"),
  },
  {
    path: "/PersonalRealname",
    name: "PersonalRealname",
    component: () => import("../views/PersonalRealname.vue"),
  },
  {
    path: "/PersonalRealnames",
    name: "PersonalRealnames",
    component: () => import("../views/PersonalRealnames.vue"),
  },
];
const router = new VueRouter({
  routes,
  mode: "hash",
});
router.beforeEach((to, from, next) => {
  if (
    to.path !== "/login" &&
    to.path !== "/registers" &&
    to.path !== "/PersonalRealname" &&
    to.path !== "/PersonalRealnames" &&
    !sessionStorage.getItem("token")
  ) {
    next("/login");
  } else {
    next();
  }

  // if (to.name == 'login') {
  // 	console.log(to.name);
  // }
  // next()
});

export default router;
