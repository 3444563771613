<template>
  <el-container style="width: 100%; height: 100%;">
    <el-header>
      <div class="header">
        <ul class="header-left">
          <div class="logo" :class="{ 'islogo': isCollapse }">
            <img src="@/assets/login/logo.png" alt="" v-show="!isCollapse">
            <img src="@/assets/login/logo1.jpg" alt="" v-show="isCollapse">
          </div>
          <div class="toggle" :class="{ 'isCollapse': isCollapse }" @click="isCollapse = !isCollapse"
            v-show="$store.state.hide == 1">
            <span v-if="isCollapse"><i class="el-icon-s-unfold"></i> </span>
            <span v-else><i class="el-icon-s-fold"></i> </span>
          </div>
        </ul>
        <div class="header-right">
          <div class="tips">
            <span>
              你好，欢迎{{ companyName }}登录
            </span>
          </div>
          <div class="rightBox">
            <div @click="homePage">
              <span style="font-size: 18px;">首页</span>
            </div>
            <div class="rightBox1">
              <el-popover placement="bottom" width="240" trigger="click">
                <div>
                  <div class="rightBox-top">
                    <img src="../assets/avatar.png" alt="" />
                    <div>
                      <p>{{ cardname }}</p>
                      <p>{{ username }}</p>
                    </div>
                  </div>
                  <div class="ziliao">
                    <p style="font-size: 16px;margin-bottom: 10px;padding-left: 12px;" @click="skipRoute">企业资料</p>
                    <p style="font-size: 16px;margin-bottom: 10px;padding-left: 12px;" @click="userRoute">个人资料</p>
                  </div>

                  <el-button style="width: 100%;" @click="RealName">退出系统</el-button>

                </div>
                <span style="font-size: 18px;border: none;" slot="reference">我的</span>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </el-header>
    <el-container class="nav" style=" overflow-y: auto; 	overflow-x: hidden;">
      <el-aside class="wwwscrolly" :width="isCollapse ? '64px' : '220px'" v-show="$store.state.hide == 1">
        <div>
          <el-menu router  :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen"
            @close="handleClose" :collapse="isCollapse" :unique-opened="true">
            <!-- 首页 -->
            <el-menu-item index="/homepage/homepage" class="left-item">
              <i class="el-icon-s-data"></i>
              <span slot="title">首页</span>
            </el-menu-item>

            <el-submenu index="/xitong">
              <template slot="title">
                <i class="el-icon-user"></i>
                <span>系统管理</span>
              </template>
              <el-menu-item index="/staff/account">
                <span slot="title">员工通信录</span>
              </el-menu-item>
              <!-- <el-menu-item index="/qualifications/qlist">
                <span slot="title">渠道账号</span>
              </el-menu-item> -->
              <el-menu-item index="/xitong/zuzhi">
                <span slot="title">组织架构</span>
              </el-menu-item>
              <el-menu-item index="/xitong/muban">
                <span slot="title">合同模板</span>
              </el-menu-item>
              <!-- <el-menu-item index="/xitong/bumenList">
                <span slot="title">部门列表</span>
              </el-menu-item> -->
            </el-submenu>
            <el-submenu index="/xiangmu">
              <template slot="title">
                <i class="el-icon-notebook-1"></i>
                <span>项目管理</span>
              </template>
              <el-menu-item index="/xiangmu/xmku">
                <span slot="title">项目工程库</span>
              </el-menu-item>
              <!-- <el-menu-item index="/xiangmu/gcList">
                <span slot="title">项目工程列表</span>
              </el-menu-item> -->
            </el-submenu>
            <el-submenu index="/information">
              <template slot="title">
                <i class="el-icon-film"></i>
                <span>保函审批</span>
              </template>
              <el-menu-item index="/information/Purchase/dztb">
                <span slot="title">在线保函</span>
              </el-menu-item>
              <el-menu-item index="/information/Purchase/gkzb">
                <span slot="title">投标保函</span>
              </el-menu-item>
              <el-menu-item index="/information/Purchase/negotiation">
                <span slot="title">履约保函</span>
              </el-menu-item>
              <el-menu-item index="/information/Purchase/consultations">
                <span slot="title">业主支付保函</span>
              </el-menu-item>
              <el-menu-item index="/information/Purchase/invitation">
                <span slot="title">农民工工资保函</span>
              </el-menu-item>
              <el-menu-item index="/information/Purchase/inquiry">
                <span slot="title">质量保函</span>
              </el-menu-item>
              <el-menu-item index="/information/Purchase/source">
                <span slot="title">预付款保函</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/diaocha/baogaoList" class="left-item">
              <i class="el-icon-s-data"></i>
              <span slot="title">调查报告</span>
            </el-menu-item>
            <el-submenu index="/wdbh">
              <template slot="title">
                <i class="el-icon-notebook-1"></i>
                <span>保函管理</span>
              </template>
              <el-menu-item index="/wdbh/baohanList">
                <span slot="title">保函列表</span>
              </el-menu-item>
              <el-menu-item index="/wdbh/userList">
                <span slot="title">反担保用户列表</span>
              </el-menu-item>
              <el-menu-item index="/wdbh/fandianbao">
                <span slot="title">个人反担保记录</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/Guarantee">
              <template slot="title">
                <i class="el-icon-notebook-1"></i>
                <span>客户管理</span>
              </template>
              <el-menu-item index="/Guarantee/list">
                <span slot="title">施工单位列表</span>
              </el-menu-item>
              <el-menu-item index="/Guarantee/dailiList">
                <span slot="title">业务渠道列表</span>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="daihouguanli">
              <template slot="title">
                <i class="el-icon-notebook-1"></i>
                <span>贷后管理</span>
              </template>
              <el-menu-item index="/daihouguanli/list">
                <span slot="title">工程列表</span>
              </el-menu-item>
              <!-- <el-menu-item index="/daihouguanli/jindu">
                <span slot="title">工程进度</span>
              </el-menu-item> -->
              <el-menu-item index="/wdbh/xubaoList">
                <span slot="title">续保列表</span>
              </el-menu-item>
              <el-menu-item index="/wdbh/jiebaoList">
                <span slot="title">解保列表</span>
              </el-menu-item>
              <el-menu-item index="/wdbh/fangqi">
                <span slot="title">弃保列表</span>
              </el-menu-item>
            </el-submenu>
            <!-- 财务管理 -->
            <el-submenu index="/finance">
              <template slot="title">
                <i class="el-icon-coin"></i>
                <span>财务管理</span>
              </template>
              <el-menu-item index="/finance/payment">
                <span slot="title">支付记录</span>
              </el-menu-item>
              <el-menu-item index="/kaipiao/dzzp">
                <span slot="title">发票列表</span>
              </el-menu-item>
              <el-menu-item index="/finance/shenji">
                <span slot="title">审计报告</span>
              </el-menu-item>
              <!-- <el-menu-item index="/daihouguanli/jiaofei">
                <span slot="title">缴费记录</span>
              </el-menu-item> -->
            </el-submenu>
            <el-submenu index="/staff/accounts">
              <template slot="title">
                <i class="el-icon-coin"></i>
                <span>流程管理</span>
              </template>
              <el-menu-item index="/staff/accounts">
                <!-- <i class="el-icon-s-operation"></i> -->
                <span slot="title">流程列表</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/zjps">
              <template slot="title">
                <i class="el-icon-coin"></i>
                <span>专家评审</span>
              </template>
              <el-menu-item index="/zjps/zjList">
                <span slot="title">专家列表</span>
              </el-menu-item>
              <el-menu-item index="/zjps/hyList">
                <span slot="title">评审会议</span>
              </el-menu-item>
              <el-menu-item index="/zjps/zlList">
                <span slot="title">会议资料</span>
              </el-menu-item>
              <el-menu-item index="/zjps/jieguo">
                <span slot="title">评审决议</span>
              </el-menu-item>
              <!-- <el-menu-item index="/finance/payment">
                <span slot="title">电子普票</span>
              </el-menu-item> -->
              <!-- <el-menu-item index="/finance/payment">
                <span slot="title">纸质专票</span>
              </el-menu-item>
              <el-menu-item index="/finance/payment">
                <span slot="title">纸质普票</span>
              </el-menu-item> -->
            </el-submenu>
          </el-menu>

        </div>
      </el-aside>
      <el-aside class="wwwscrolly" :width="isCollapse ? '64px' : '220px'"
        style=" overflow-y: auto; 	overflow-x: hidden;" v-show="$store.state.hide == 2">
        <div>
          <el-menu router  :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen"
            @close="handleClose" :collapse="isCollapse" :unique-opened="true">
            <!-- 首页 -->
            <el-menu-item index="/msg/index">
              <span slot="title">系统通知</span>
            </el-menu-item>
          </el-menu>

        </div>
      </el-aside>
      <el-aside class="wwwscrolly" :width="isCollapse ? '64px' : '220px'"
        style=" overflow-y: auto; 	overflow-x: hidden;" v-show="$store.state.hide == 3">
        <div>
          <el-menu router  :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen"
            @close="handleClose" :collapse="isCollapse" :unique-opened="true">
            <!-- 首页 -->
            <el-menu-item index="/information/Purchase/All">
              <span slot="title">待办事项</span>
            </el-menu-item>
          </el-menu>

        </div>
      </el-aside>
      <el-main style="background: #F5F6FA;">

        <!-- $route.meta.keepAlive   true 缓存  false  不缓存 -->
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"> </router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"> </router-view>



        <div class="bottomBox">
          <p> 河南省豫资大数据研究院有限公司版权所有，未经书面授权禁止使用 <br /> Copyright © 2022-2023 by www.train.hnzyxsbh.com all rights reserved
          </p>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {
  out,
} from "@/api/personal";
export default {
  data() {
    return {
      value: [],
      isCollapse: false,
      companyName: '',
      cardname: '',
      username: '',
    };
  },
  created() {
    this.companyName = sessionStorage.getItem('companyName')
    this.cardname = sessionStorage.getItem('cardname')
    this.username = sessionStorage.getItem('username')
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log("+++++", key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log("-----", key, keyPath);
    },
    // 企业资料
    skipRoute() {
      this.$router.push("/personal");
    },
    // 个人资料
    userRoute() {
      this.$router.push("/personalUser");
    },
    RealName() {
      // this.$router.push("/RealName/PersonalRealname");
      this.$confirm('确定退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        out().then(response => {
          console.log(response);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("companyName");
          sessionStorage.removeItem("cardname");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("unify_code");
          sessionStorage.removeItem("platformcode");
          sessionStorage.removeItem("renzhengxinxi");
          sessionStorage.removeItem("id");
          this.$router.push("/login");
        });
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      }).catch(() => {

      });
    },

    homePage() {
      this.$router.push('/')
    }
  },
};
</script>
<style scoped lang="scss">
.el-submenu__title {
  color: #1795ff !important;
  font-weight: bold;
}

.el-menu-item {
  /* color: #1795ff !important; */
  padding-left: 40px !important;
}

.el-submenu .el-menu-item span {
  margin-left: 20px;
}

.left-item {
  padding-left: 22px !important;
}

::v-deep.el-menu-item.is-active {
  color: white !important;
  background-color: #1795ff !important;
  // border-radius: 2px !important;
  // border-radius: 8px 8px 8px 8px !important;
}

.sjdp {
  width: 100vw;
  height: 100vh;
}

ul {
  list-style: none;
}


.homebutton {
  height: 44px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.homeLess {
  width: 288px;
  height: 200px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* padding: 0 5%; */
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  /* min-width: 1200px; */
  /* margin-left: 200px; */
}

.header .header-left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.header .header-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.header .header-right .img1 {
  position: absolute;
  right: 60px;
  top: -3px;
  text-align: center;
  display: inline-block;
  height: 100%;
  font-size: 18px;
}

.header .header-right .img2 {
  position: absolute;
  right: 10px;
  top: 13px;
  width: 20px;
  height: 20px;
  border: none;
  font-size: 18px;
}

.header .header-right .img2 .img3 {
  position: absolute;
  left: -5px;
  bottom: -13px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  // min-height: 400px;
}

.nav {
  position: relative;
  // height: calc(90vh + 58px);
  width: 100%;
  height: 100%;
}

.el-main {
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toggle {
  position: fixed;

  left: 205px;
  text-align: center;
}

.isCollapse {
  left: 50px;
  top: 7px;
}

.toggle span {
  margin-left: 25px;
}

.logo {
  height: 100%;

  img {
    width: 200px;
    height: 80%;
  }
}

.islogo {
  img {
    width: 30px;
    height: 30px;
  }
}

::v-deep.el-icon-s-unfold,
.el-icon-s-fold {
  font-size: 26px !important;
}

.bottomBox {
  // margin-top: 3px;
  justify-content: center;
  display: flex;
  width: 100%;
  align-content: center;
  height: 58px;
  padding: 9px 0;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #999;
  background: #fff;
}

.tips {
  width: 80%;
  // padding-right: 120px;
  box-sizing: border-box;
  text-align: right;
}

.rightBox {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;

}

.rightBox-top {
  display: flex;
  margin-bottom: 8px;
  padding: 12px;

  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: left;
      // margin: 10px 0;
      margin-bottom: 10px;
      width: 100%;
      font-size: 16px;
    }
  }
}

.ziliao {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  margin-bottom: 10px;
}

.wwwscrolly::-webkit-scrollbar,
.wwwscroll::-webkit-scrollbar,
.wwwscrollx::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.el-header{
  padding: 0 20px 0 10px !important;
}
</style>
