import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as echarts from "echarts";
import "@/utils/drag.js";
//ui引入
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import confirmMy from "./components/tan.js";
Vue.prototype.$confirmMy = confirmMy;

//使用
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);
Vue.prototype.$baseUrl = "https://api.hnzyxsbh.com/";
Vue.config.productionTip = false;
Vue.filter("dateFormat", function (value) {
  let date = new Date(value * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s; //多种时间格式的拼接
});
Vue.filter("dateFormats", function (value) {
  let date = new Date(value * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d; //多种时间格式的拼接
});
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
