<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    watch: {
        $route(to, from) {
            if (to.path == '/personal' || to.path == '/xiangmu/detail' || to.path == "/RealName/PersonalRealname" || to.path == "/personalUser" || to.path == "/wdbh/bhxq" || to.path == "/wdbh/shenhe" || to.path == "/finance/detail" || to.path == "/Guarantee/detail" || to.path == "/Guarantee/companyDetail" || to.path == "/kuaidi" || to.path == "/exxpress" || to.path == "/daihouguanli/detail" || to.path == "/diaocha/detail" || to.path == "/information/Purchase/zxbhxq") {
                this.$store.dispatch("AsyicOPENSHOW", '');
            } else if (to.path == "/msg/index") {
                this.$store.dispatch("AsyicOPENSHOW", 2);
            } else if (to.path == "/information/Purchase/All") {
                this.$store.dispatch("AsyicOPENSHOW", 3);
            } else {
                this.$store.dispatch("AsyicOPENSHOW", 1);
            }
        }
    },
}
</script>
<style lang="scss">
@import url('./utils/css/common.scss');

* {
    margin: 0;
    padding: 0
}

.el-popover {
    padding: 0 !important;
}

li {
    list-style: none
}

img {
    vertical-align: top;
    border: none
}

/*清除默认样式*/
html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    // overflow: hidden;
}

#app {
    width: 100%;
    height: 100%;
}

.block {
    padding-right: 15px;
    position: static !important;
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding-top: 0 !important;
    padding-bottom: 10px;
}

body {
    font-family: "Microsoft YaHei";
    font-size: 14px;
    color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

ul,
ol {
    list-style: none;
}

img {
    border: none;
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: #232323;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
}

input,
textarea {
    outline: none;
    border: none;
}

textarea {
    resize: none;
    overflow: auto;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.table {
    margin-top: 0 !important;
}

.body {
    padding: 0 10px;
}
</style>
