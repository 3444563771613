import request from "@/utils/request"



//个人中心
export const grzx = (user_id) => {

    return request({
      method: "POST",
      data:
        {user_id},
      url: "/index/usercenter",
    });
  };
  //退出登录
export const out = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/index/logout",
    });
  };
// 企业资质
export const zizhi = () => {

    return request({
      method: "POST",
      url: "/company/zizhi",
    });
  };
  // 企业业绩
export const yeji = (e) => {

    return request({
      method: "POST",
      data:e,
      url: "/index/yeji",
    });
  };
  export const gongsirenzheng = (e) => {

    return request({
      method: "POST",
      data:e,
      url: "/company/getinfo",
    });
  };
  //公章使用记录
  export const seal = (e) => {

    return request({
      method: "POST",
      data:{
        user_id:e
      },
      url: "/index/gongsizhang",
    });
  };
  //法人章使用记录
  export const corporate = (e) => {

    return request({
      method: "POST",
      data:{
        user_id:e
      },
      url: "/index/farenzhang",
    });
  };
  //修改密码
  export const modify = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/staff/password",
    });
  };
  //企业资料--获取法人章认证
  export const frzrenzheng = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "company/get_frzrenzheng",
    });
  };
  //企业资料--企业资料--获取公章认证
  export const gzrenzheng = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "company/get_gzrenzheng",
    });
  };
  //企业资料--修改个人资料
  export const update_info = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/my/update_info",
    });
  };
  //获取企业信息（新）
  export const getNewinfo = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/company/getNewinfo",
    });
  };

